import React, {useState} from "react";
import { Waypoint } from "react-waypoint";
import { useSpring, animated } from "@react-spring/web";

interface PulseProps {
  delay?: number;
}

export default function Pulse({ children, delay = 350 }: React.PropsWithChildren<PulseProps>) {
  const [inView, setInView] = useState(false);

  const transition = useSpring({
    delay,
    to: {
      scale: !inView ? 0.5 : 1,
    }
  })

  return (
    <Waypoint onEnter={() => setInView(true)}>
      <animated.div style={transition}>
        {children}
      </animated.div>
    </Waypoint>
  )
}